import React from 'react';
import './App.css';
import { Routes, Route, Link } from "react-router-dom";
import Calculator from './components/Calculator/Calculator';
import { AppBar, Box, Toolbar, Typography } from '@mui/material';
import About from './pages/About/About';
import Privacy from './pages/PrivacyPolicy/Privacy';

function App() {

  const linkStyleHeader = {
    color: '#D4AF37',
    textDecoration: 'none',
    fontFamily: 'Squada One, cursive',
    fontSize: '30px'
  };

  const linkStyle = {
    color: '#fff',
    fontWeight: 'bold'
  };

  return (
    <Box sx={{ display: 'flex' }} className="mfp">
      <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, backgroundColor: '#000' }}>
        <Toolbar>
          <Typography variant="h6" noWrap component="div">
          <Link to="/" style={linkStyleHeader}>MY FINANCE PLANNER</Link>
          </Typography>
          <Typography sx={{ pl: '20px', pr: '20px', color: '#fff' }}><Link to="/about" style={linkStyle}>About</Link></Typography>
          <Typography sx={{ pl: '20px', pr: '20px', color: '#fff' }}><Link to="/privacy-policy" style={linkStyle}>Privacy Policy</Link></Typography>
        </Toolbar>
      </AppBar>
      <Routes>
        <Route path="/" element={<Calculator />} />
        <Route path="/about" element={<About />} />
        <Route path="/privacy-policy" element={<Privacy />} />
      </Routes>
    </Box>
  );
}

export default App;