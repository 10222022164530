import React from 'react';
import '../../App.css';
import { Box, CssBaseline, Container } from '@mui/material';


function About() {
  return (
    <>
      <CssBaseline />
        <Container maxWidth="md">
          <Box sx={{ height: '100vh' }}>
            <br /><br /><br />
            <header>
            <h2>About</h2>
            </header>
            <main>
            <p>My Finance Planner is a tool designed to let you visualise your finances, and help you plan for your future, 
              be it for saving for something 5 years in the future or retirement.</p>
            <p>By entering your details, you are able to get an idea of the amount of money you can potentially earn, 
              and potentially invest, to further to increase your 'pot'.</p>
              
            <h2>How to use</h2>
            <p>Enter as many details in the form as you can, to get an indicator of the amount of money you could potentially earn.</p>
            <table>
              <tr>
                <td>Gross Salary</td>
                <td>Your salary, pre-tax</td>
              </tr>
              <tr>
                <td>Retirement Age</td>
                <td>What age you intend to retire at. This can also be used as the age you want to aim towards, if you want to save for a short term goal.</td>
              </tr>
              <tr>
                <td>Current Age</td>
                <td>Your current age</td>
              </tr>
              <tr>
                <td>My Pension Contribution</td>
                <td>What percentage of your salary do you put away towards your pension</td>
              </tr>
              <tr>
                <td>Company Pension Contribution</td>
                <td>What percentage of your salary does your employer contribute</td>
              </tr>
              <tr>
                <td>Pension Growth Rate</td>
                <td>The rate at which your pension should mature (typically 5%)</td>
              </tr>
              <tr>
                <td>Investment Growth Rate</td>
                <td>If you invest the remaining net wage after costs, this is the rate of growth of those investments. The assumption here is that a value greater than 0 assume all remaining net wage is invested</td>
              </tr>
              <tr>
                <td>Monthly Costs</td>
                <td>Enter recurring monthly costs</td>
              </tr>
              <tr>
                <td>Annual Costs</td>
                <td>Enter recurring annual costs</td>
              </tr>
              <tr>
                <td>One off Costs</td>
                <td>Enter one off costs</td>
              </tr>
            </table>
<br /><br />
            </main>
          </Box>
      </Container>
    </>
  )
}

export default About;