import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import RecurringAmount from '../../models/RecurringAmount';
import RecurringAmountArrayStoreObject from '../../models/RecurringAmountArrayStoreObject';
import { RecurringTypes } from '../../models/RecurringTypes';

const arrayList: Array<RecurringAmount> = [];
const newItem: RecurringAmount = { id: 1, label: 'Enter description', value: 0 };
arrayList.push(newItem);

const initialState : Array<RecurringAmountArrayStoreObject> = [
  { keyId : RecurringTypes.DEFAULT.toString(), value : arrayList }
];
/*
initialStateArray.push({ keyId : RecurringTypes.ANNUAL.toString(), value : arrayList });

var val = initialStateArray.find(x => x.keyId === "ANNUAL");
if (val !== undefined) {
  console.log('found', val.value);
}
*/

const recurringAmountsSlice = createSlice({
  name: 'recurringAmounts',
  initialState: {
    value: initialState
  },
  reducers: {
    initialise: (state, action: PayloadAction<string>) => {
      var val = state.value.find(x => x.keyId === action.payload);
      if (!val) {
        const arrayList: Array<RecurringAmount> = [];
        const newItem: RecurringAmount = { id: 1, label: '', value: 0 };
        arrayList.push(newItem);

        state.value.push({ keyId : action.payload, value : arrayList });
      }
    },
    addItem: (state, action: PayloadAction<{key: string, item: RecurringAmount}>) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      var val = state.value.find(x => x.keyId === action.payload.key);
      if (val !== undefined) {
        val.value.push(action.payload.item);
      }
    },
    removeItem: (state, action: PayloadAction<{key: string, itemIndex: number}>) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      var val = state.value.find(x => x.keyId === action.payload.key);
      if (val !== undefined) {
        val.value.splice(action.payload.itemIndex, 1);
      }
    },
    updateItem: (state, action: PayloadAction<{key: string, index : number, item: RecurringAmount}>) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      var val = state.value.find(x => x.keyId === action.payload.key);
      if (val !== undefined) {
        val.value[action.payload.index] = action.payload.item;
      }
    }
  },
})

// Action creators are generated for each case reducer function
export const { initialise, addItem, removeItem, updateItem } = recurringAmountsSlice.actions;

export default recurringAmountsSlice.reducer;