import IFinance from "../models/IFinance";
import RecurringAmount from "../models/RecurringAmount";

/**
 * The formula for calculating the future value of an ordinary annuity 
 * where a series of equal payments are made at the end of each of multiple periods
 *
 * @param rate - Interest rate
 * @param numOfPeriods - Number of periods (in months)
 * @param pmt - Payment, the amount per period
 */
export const futureValue = (rate : number, 
    numOfPeriods : number, 
    pmt : number,
    presentValue: number = 0) => {
	
    let decimalRate = rate / 100; // 5/100

    // FV = PV*(1+i)^n - to work out what your present pot can amount to

    let fv = presentValue * Math.pow((1 + decimalRate), (numOfPeriods / 12));

    // P = PMT [((1 + r)n - 1) / r] - to work out how much more you will generate
    
    let monthlyInterestRate = decimalRate / 12; // 0.05/12 = 0.004
  
    let inBrackets = 1 + monthlyInterestRate;
    let val = pmt * ((Math.pow(inBrackets, numOfPeriods) - 1) / monthlyInterestRate);
  
    return (val + fv);
};

/*
Income	Income Tax bands 2022/23
£0 to £12,570 - 0%
£12,571 to £50,270 - Basic rate: 20%
£50,271 to £150,000 - Higher rate: 40%
Over £150,000 - Additional rate: 45%

National Insurance
13.25% of your weekly earnings between £190 and £967 (2022/23)
3.25% of your weekly earnings above £967.
*/
export const calculateIncomeTaxDeductions = (grossSalary : number) => {
    let deduction = 0;

    let incomeTaxDeduction = 0;
    if (grossSalary > 12570) {
        if (grossSalary >= 50270) {
            incomeTaxDeduction += ((20/100) * (50270 - 12570)); 
        }
        else {
            incomeTaxDeduction += ((20/100) * (grossSalary - 12570)); 
        }
    }

    if (grossSalary > 50270) {
        if (grossSalary >= 150000) {
            incomeTaxDeduction += ((40/100) * (150000 - 50270)); 
        }
        else {
            incomeTaxDeduction += ((40/100) * (grossSalary - 50270)); 
        }
    }

    if (grossSalary > 150000) {
        incomeTaxDeduction += ((45/100) * (grossSalary - 150000)); 
    }

    deduction += incomeTaxDeduction;

    // console.log('incometax', deduction);

    return deduction;
};

export const calculateNationalInsuranceDeductions = (grossSalary : number) => {
    let deduction = 0;
    
    let nationalInsuranceDeduction = 0;
    let weeklyPay = grossSalary / 52;
    if (weeklyPay > 190) {
        if (weeklyPay >= 967) {
            nationalInsuranceDeduction += ((13.25/100) * (967 - 190)); 
            nationalInsuranceDeduction += ((3.25/100) * (weeklyPay - 967)); 
        }
        else {
            nationalInsuranceDeduction += ((13.25/100) * (weeklyPay - 190)); 
        }
    }

    deduction += (nationalInsuranceDeduction * 52);

    // console.log('natins', deduction);

    return deduction;
};

export const calculateFinance = (
    grossSalary : number, 
    retirementAge : number, 
    currentAge : number, 
    myPensionContribution : number, 
    companyPensionContribution : number, 
    pensionGrowthRate : number, 
    pensionPotAmount: number,
    investmentGrowthRate : number,
    monthlyInvestmentAmount : number,
    recurringMonthlyCosts: RecurringAmount[],
    recurringAnnualCosts: RecurringAmount[],
    oneOffCosts: RecurringAmount[]) => {
	
    var finance = {} as IFinance;

    finance.grossSalary = grossSalary;
    finance.retirementAge = retirementAge;
    finance.currentAge = currentAge;
    finance.myPensionContribution = myPensionContribution;
    finance.companyPensionContribution = companyPensionContribution;
    finance.pensionGrowthRate = pensionGrowthRate;
    finance.pensionPotAmount = pensionPotAmount;
    finance.investmentGrowthRate = investmentGrowthRate;
    finance.monthlyInvestmentAmount = monthlyInvestmentAmount;
    finance.yearsToRetirement = retirementAge - currentAge;
    finance.averageLifeExpectancy = 81;
    finance.numYearsRetirement = finance.averageLifeExpectancy - retirementAge;
    finance.annualIncomeTaxDeductions = calculateIncomeTaxDeductions(finance.grossSalary);
    finance.annualNationalInsuranceDeductions = calculateNationalInsuranceDeductions(finance.grossSalary);
    finance.annualDeductions = finance.annualIncomeTaxDeductions + finance.annualNationalInsuranceDeductions;
    finance.monthlyDeductions = finance.annualDeductions / 12;
    finance.annualNetPay =  finance.grossSalary - finance.annualDeductions;
    finance.monthlyNetPay = finance.annualNetPay / 12;

    finance.recurringMonthlyCostsTotal = (recurringMonthlyCosts) ? recurringMonthlyCosts.map(x => x.value).reduce((sum, item) => sum + Number(item), 0) : 0;
    finance.recurringAnnualCostsTotal = (recurringAnnualCosts) ? recurringAnnualCosts.map(x => x.value).reduce((sum, item) => sum + Number(item), 0) : 0;

    finance.totalAnnualCosts = (finance.recurringMonthlyCostsTotal * 12) + finance.recurringAnnualCostsTotal;
    finance.totalMonthlyCosts = finance.totalAnnualCosts / 12;

    finance.oneOffCosts = (oneOffCosts) ? oneOffCosts.map(x => x.value).reduce((sum, item) => sum + Number(item), 0) : 0;
    
    finance.amountFromRemainingDisposableIncomeAtRetirement = ((finance.annualNetPay - finance.totalAnnualCosts) * finance.yearsToRetirement) - finance.oneOffCosts;
    finance.annualRemainingDisposableIncome =  finance.amountFromRemainingDisposableIncomeAtRetirement / finance.yearsToRetirement;
    finance.monthlyRemainingDisposableIncome = finance.annualRemainingDisposableIncome / 12;
    
    finance.totalPensionRate = Number(finance.myPensionContribution) + Number(finance.companyPensionContribution);
    finance.annualPensionAmount = (finance.totalPensionRate / 100) * finance.grossSalary;
    finance.monthlyPensionAmount = finance.annualPensionAmount / 12;
    
    finance.pensionTotalAtRetirement = futureValue(finance.pensionGrowthRate,  finance.yearsToRetirement * 12, finance.monthlyPensionAmount, finance.pensionPotAmount);
    finance.investmentTotalAtRetirement = (Number(finance.investmentGrowthRate) === 0) ? finance.amountFromRemainingDisposableIncomeAtRetirement : futureValue(Number(investmentGrowthRate), finance.yearsToRetirement * 12, finance.monthlyInvestmentAmount);
    
    finance.totalAtRetirement = finance.pensionTotalAtRetirement + finance.investmentTotalAtRetirement;
    finance.retirementSalary = finance.totalAtRetirement / finance.numYearsRetirement;
    finance.retirementMonthly = finance.retirementSalary / 12;

    return finance;
};