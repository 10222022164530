import React from 'react';
import '../../App.css';
import { Box, CssBaseline, Container } from '@mui/material';


function Privacy() {

  const openCookiePreferences = (e : any) => {
    document.getElementById('open_preferences_center')?.click();
  };

  return (
    <>
      <CssBaseline />
        <Container maxWidth="md">
          <Box sx={{ height: '100vh' }}>
            <br /><br /><br />
            <header>
              <h2>Privacy Policy</h2>
            </header>
            <main>
            <p>My Finance Planner respects users privacy and does not store any of the details entered in the calculator anywhere 
              other than the users browser. We do not collect this information, and store elsewhere.</p>
            <p>Our aim is to provide a useful tool to our users, and not to collect our user's personal data.</p>
            <h3>Cookies</h3>
            <p>My Finance Planner does not produce any cookies of it's own that provide any functionality of the site.</p> 
            <p>The optional cookies that <i>are</i> generated on the site are:</p>
            <p><b>Analytical/performance cookies</b>. These allow us to count the number of visitors and can help us to improve 
            the way our site works.</p>
            <p><b>Targeting &amp; advertising cookies</b>. These allow us to show relevant ads to the user.</p>
            <button onClick={openCookiePreferences}>Change Your Preferences</button>
            </main>
          </Box>
      </Container>
    </>
  )
}

export default Privacy;