import React from 'react';
import { useSelector } from 'react-redux';
import { Box, Button, Card, CardContent, CssBaseline, Grid, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material';
import RecurringAmount from '../../models/RecurringAmount';
import { RootState, useAppDispatch } from '../../redux/store';
import { addItem, removeItem, initialise, updateItem } from '../../redux/reducers/recurringAmountsSlice';
import DeleteIcon from '@mui/icons-material/Delete';

type AppProps = {
  keyId: string;
  title: string;
};

const buttonStyle = {
  color: '#000',
  borderColor: '#000',
  // fontFamily: "Squada One"
};

const RecurringAmountRepeater = ({ keyId, title }: AppProps): JSX.Element => {
  const recurringCosts = useSelector((state: RootState) => state.recurringAmounts.value.find(x => x.keyId === keyId));
  
  const dispatch = useAppDispatch();

  if (!recurringCosts) {
    dispatch(initialise(keyId));
    // console.log('doesnt exist', keyId);
  }

  const handleRecurringCostChange = (e : any, index : number, type : number) => {
    let itemFromArray = recurringCosts?.value[index];
    const itemToUpdate = Object.assign({}, itemFromArray);
    if (type === 1) {
      itemToUpdate.label = e.target.value as string;
    } else if (type === 2) {
      itemToUpdate.value = e.target.value as number;
    }    
    dispatch(updateItem({ key: keyId, index: index, item: itemToUpdate }));
  };

  const addNewItem = (e : any) => {
    var maxId = (recurringCosts) ? Math.max(...recurringCosts.value.map(x => x.id)) : 0;
    const newItem: RecurringAmount = { id: maxId + 1, label: '', value: 0 };
    dispatch(addItem({ key: keyId, item: newItem }));
  };

  const removeExistingItem = (e : any, index : number,) => {
    dispatch(removeItem({ key: keyId, itemIndex: index }));
  };

  let amount = (recurringCosts) ? (recurringCosts.value.map(x => x.value).reduce((sum, item) => sum + Number(item), 0)) : 0;



  return (
    <>
      <Box sx={{ overflow: 'auto' }}>
        <CssBaseline />
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Typography variant="h2" sx={{ fontSize: '18px', fontWeight: 'bold' }} color="text.secondary" gutterBottom>
              {title} (£{amount.toFixed(2)})
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Box>
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
        <TableHead>
            <TableRow>
              <TableCell>Description</TableCell>
              <TableCell>Amount</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {recurringCosts &&
              recurringCosts.value.map((element : RecurringAmount, index : number) => {
              return (
                  <TableRow key={index}>
                    <TableCell>
                      <TextField key={`${keyId}-${element.id}-label`} placeholder="Enter description" variant="outlined" type="text" value={element.label} onChange={e => handleRecurringCostChange(e, index, 1)} />
                    </TableCell>
                    <TableCell>
                      <TextField key={`${keyId}-${element.id}-value`} variant="outlined" type="number" value={element.value} onChange={e => handleRecurringCostChange(e, index, 2)} />
                    </TableCell>
                    <TableCell>
                      <IconButton aria-label="delete" onClick={e => removeExistingItem(e, index)}>
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
              )
            })}
            <TableRow>
              <TableCell>
                &nbsp;
              </TableCell>
              <TableCell>
                <Button variant="outlined" onClick={e => addNewItem(e)} style={buttonStyle}>Add Item</Button>
              </TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default RecurringAmountRepeater;