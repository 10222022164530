import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import IFinance from '../../models/IFinance';

const initialState = {} as IFinance;
initialState.grossSalary = 0;
initialState.currentAge = 33;
initialState.retirementAge = 60;
initialState.myPensionContribution = 5;
initialState.companyPensionContribution = 3;
initialState.pensionGrowthRate = 2.5;
initialState.pensionPotAmount = 0;
initialState.investmentGrowthRate = 5;
initialState.monthlyInvestmentAmount = 0;

const financeSlice = createSlice({
  name: 'finance',
  initialState: {
    value: initialState,
  },
  reducers: {
    updateFinance: (state, action: PayloadAction<{financeObject: IFinance}>) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      //let arrayToUpdateItemIn = state.value as IFinance;
      state.value = action.payload.financeObject;
      // console.log('reducer/updateItem - item: ' + action.payload.key, action.payload.index);
    }
  },
})

// Action creators are generated for each case reducer function
export const { updateFinance } = financeSlice.actions;

export default financeSlice.reducer;