import { configureStore } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import recurringAmountsSlice from './reducers/recurringAmountsSlice';
import financeSlice from './reducers/FinanceSlice';
import { enableMapSet } from 'immer';
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';
import { combineReducers } from 'redux';

enableMapSet();

// https://blog.logrocket.com/persist-state-redux-persist-redux-toolkit-react/
const persistConfig = {
  key: 'root',
  storage
};

const rootReducer = combineReducers({ 
  finance: financeSlice,
  recurringAmounts: recurringAmountsSlice
})

const persistedReducer = persistReducer(persistConfig, rootReducer)

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: false
  })
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const persistor = persistStore(store);
export default store;