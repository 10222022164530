import './Calculator.css';
import { Grid, Paper, TextField, Table, TableCell, TableContainer, TableHead, TableRow, TableBody, Typography, Card, CardContent, Stack } from '@mui/material';
import RecurringAmountRepeater from '../RecurringAmountRepeater/RecurringAmountRepeater';
import { RootState, useAppDispatch } from '../../redux/store';
import { useSelector } from 'react-redux';
import { RecurringTypes } from '../../models/RecurringTypes';
import { calculateFinance } from '../../helpers/Finance';
import { updateFinance } from '../../redux/reducers/FinanceSlice';

function Calculator() {

  const dispatch = useAppDispatch();

  let finance = useSelector((state: RootState) => state.finance.value);

  const recurringMonthlyCosts = useSelector((state: RootState) => state.recurringAmounts.value.find(x => x.keyId === RecurringTypes.MONTHLY));
  const recurringAnnualCosts = useSelector((state: RootState) => state.recurringAmounts.value.find(x => x.keyId === RecurringTypes.ANNUAL));
  const oneOffCosts = useSelector((state: RootState) => state.recurringAmounts.value.find(x => x.keyId === RecurringTypes.ONE_OFF));

  finance = calculateFinance(finance.grossSalary, finance.retirementAge, finance.currentAge, finance.myPensionContribution, finance.companyPensionContribution, finance.pensionGrowthRate, finance.pensionPotAmount, finance.investmentGrowthRate, finance.monthlyInvestmentAmount, recurringMonthlyCosts?.value!, recurringAnnualCosts?.value!, oneOffCosts?.value!);

  const handleGrossSalaryChange = (e : any) => {
    finance.grossSalary = e.target.value as number;
    dispatch(updateFinance({ financeObject: finance }));
  };

  const handleRetirementAgeChange = (e : any) => {
    finance.retirementAge = e.target.value as number;
    dispatch(updateFinance({ financeObject: finance }));
  };

  const handleCurrentAgeChange = (e : any) => {
    finance.currentAge = e.target.value as number;
    dispatch(updateFinance({ financeObject: finance }));
  };

  const handleMyPensionContributionChange = (e : any) => {
    finance.myPensionContribution = e.target.value as number;
    dispatch(updateFinance({ financeObject: finance }));
  };

  const handleCompanyPensionContributionChange = (e : any) => {
    finance.companyPensionContribution = e.target.value as number;
    dispatch(updateFinance({ financeObject: finance }));
  };

  const handlePensionGrowthRateChange = (e : any) => {
    finance.pensionGrowthRate = e.target.value as number;
    dispatch(updateFinance({ financeObject: finance }));
  };

  const handlePensionPotAmountChange = (e : any) => {
    finance.pensionPotAmount = e.target.value as number;
    dispatch(updateFinance({ financeObject: finance }));
  };

  const handleInvestmentGrowthRateChange = (e : any) => {
    finance.investmentGrowthRate = e.target.value as number;
    dispatch(updateFinance({ financeObject: finance }));
  };

  const handleMonthlyInvestmentAmountChange = (e : any) => {
    finance.monthlyInvestmentAmount = e.target.value as number;
    dispatch(updateFinance({ financeObject: finance }));
  };

  return (
    <>
      {/* <Toolbar /> */}
      <Grid container spacing={2} sx={{ paddingTop: '64px' }}>
        <Grid item xs={12} md={4} sx={{ height: ['auto', 'auto', 'calc(100vh - 64px)'], overflow: ['inherit', 'inherit', 'auto'] }}>
          <Card>
            <CardContent>
              <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                Your Details
              </Typography>
              <Stack spacing={2}>
                <div>
                  <TextField id="grossSalary" label="Gross Salary (£)" variant="outlined" type="number" value={finance.grossSalary} onChange={e => handleGrossSalaryChange(e)} />
                </div>
                <div>
                  <TextField id="retirementAge" label="Retirement Age" variant="outlined" type="number" value={finance.retirementAge} onChange={e => handleRetirementAgeChange(e)} />
                </div>
                <div>
                  <TextField id="currentAge" label="Current Age" variant="outlined" type="number" value={finance.currentAge} onChange={e => handleCurrentAgeChange(e)} />
                </div>
              </Stack>
            </CardContent>
          </Card>
          <Card>
            <CardContent>
              <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                Pension ({finance.totalPensionRate}%)
              </Typography>
              <Stack spacing={2}>
                <div>
                  <TextField id="myPensionContribution" label="My Pension Contribution (%)" variant="outlined" type="number" value={finance.myPensionContribution} onChange={e => handleMyPensionContributionChange(e)} />
                </div>
                <div>
                  <TextField id="companyPensionContribution" label="Company Pension Contribution (%)" variant="outlined" type="number" value={finance.companyPensionContribution} onChange={e => handleCompanyPensionContributionChange(e)} />
                </div>
                <div>
                  <TextField id="pensionGrowthRate" label="Pension Growth Rate (%)" variant="outlined" type="number" value={finance.pensionGrowthRate} onChange={e => handlePensionGrowthRateChange(e)} />
                </div>
                <div>
                  <TextField id="pensionPotAmount" label="Pension Pot Amount (£)" variant="outlined" type="number" value={finance.pensionPotAmount} onChange={e => handlePensionPotAmountChange(e)} />
                </div>
              </Stack>
            </CardContent>
          </Card>
          <Card>
            <CardContent>
              <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                Investments
              </Typography>
              <Stack spacing={2}>
                <div>
                  <TextField id="investmentGrowthRate" label="Investment Growth Rate (%)" variant="outlined" type="number" value={finance.investmentGrowthRate} onChange={e => handleInvestmentGrowthRateChange(e)} />
                </div>
                <div>
                  <TextField id="monthlyInvestmentAmount" label="Monthly investment amount (£)" variant="outlined" type="number" value={finance.monthlyInvestmentAmount} onChange={e => handleMonthlyInvestmentAmountChange(e)} />
                </div>
              </Stack>
            </CardContent>
          </Card>
          <RecurringAmountRepeater keyId={RecurringTypes.MONTHLY} title={`Monthly Costs`} />
          <RecurringAmountRepeater keyId={RecurringTypes.ANNUAL} title={`Annual Costs`} />
          <RecurringAmountRepeater keyId={RecurringTypes.ONE_OFF} title={`One Off Costs`} />
        </Grid>
        <Grid item xs={12} md={8}>
          <br />
          <TableContainer component={Paper}>
            <Table aria-label="simple table">
              <TableHead>
                  <TableRow>
                    <TableCell>Summary</TableCell>
                    <TableCell align="right">Monthly</TableCell>
                    <TableCell align="right">Annually</TableCell>
                    <TableCell align="right">Retirement</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                    <TableCell component="th" scope="row">
                      Deductions
                    </TableCell>
                    <TableCell align="right">{finance.monthlyDeductions.toFixed(2)}</TableCell>
                    <TableCell align="right">{finance.annualDeductions.toFixed(2)}</TableCell>
                    <TableCell align="right"></TableCell>
                  </TableRow>
                  <TableRow
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                    <TableCell component="th" scope="row">
                    Net Pay (or 'take home pay')
                    </TableCell>
                    <TableCell align="right">{finance.monthlyNetPay.toFixed(2)}</TableCell>
                    <TableCell align="right">{finance.annualNetPay.toFixed(2)}</TableCell>
                    <TableCell align="right"></TableCell>
                  </TableRow>
                  <TableRow
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                    <TableCell component="th" scope="row">
                    Total Monthly Recurring Costs
                    </TableCell>
                    <TableCell align="right">{finance.recurringMonthlyCostsTotal.toFixed(2)}</TableCell>
                    <TableCell align="right">{(finance.recurringMonthlyCostsTotal * 12).toFixed(2)}</TableCell>
                    <TableCell align="right"></TableCell>
                  </TableRow>
                  <TableRow
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                    <TableCell component="th" scope="row">
                    Total Annual Recurring Cost
                    </TableCell>
                    <TableCell align="right">{(finance.recurringAnnualCostsTotal / 12).toFixed(2)}</TableCell>
                    <TableCell align="right">{finance.recurringAnnualCostsTotal.toFixed(2)}</TableCell>
                    <TableCell align="right"></TableCell>
                  </TableRow>
                  <TableRow
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                    <TableCell component="th" scope="row">
                    Remaining Net Pay (minus one-off costs)
                    </TableCell>
                    <TableCell align="right">{finance.monthlyRemainingDisposableIncome.toFixed(2)}</TableCell>
                    <TableCell align="right">{finance.annualRemainingDisposableIncome.toFixed(2)}</TableCell>
                    <TableCell align="right">{finance.amountFromRemainingDisposableIncomeAtRetirement.toFixed(2)}</TableCell>
                  </TableRow>
                  <TableRow
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                    <TableCell component="th" scope="row">
                    Pension ({finance.myPensionContribution}% + {finance.companyPensionContribution}%)
                    </TableCell>
                    <TableCell align="right">{finance.monthlyPensionAmount.toFixed(2)}</TableCell>
                    <TableCell align="right">{finance.annualPensionAmount.toFixed(2)}</TableCell>
                    <TableCell align="right">{finance.pensionTotalAtRetirement.toFixed(2)}</TableCell>
                  </TableRow>
                  <TableRow
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                    <TableCell component="th" scope="row">
                    Invested
                    </TableCell>
                    <TableCell align="right">{finance.monthlyInvestmentAmount}</TableCell>
                    <TableCell align="right"></TableCell>
                    <TableCell align="right">{finance.investmentTotalAtRetirement.toFixed(2)}</TableCell>
                  </TableRow>
                  <TableRow
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                    <TableCell component="th" scope="row">
                    Total at Retirement
                    </TableCell>
                    <TableCell align="right"></TableCell>
                    <TableCell align="right"></TableCell>
                    <TableCell align="right">{finance.totalAtRetirement.toFixed(2)}</TableCell>
                  </TableRow>
                  <TableRow
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                    <TableCell align="right">Retirement monthly</TableCell>
                    <TableCell>{finance.retirementMonthly.toFixed(2)}</TableCell>
                    <TableCell align="right" component="th" scope="row">Retirement Salary</TableCell>
                    <TableCell>{finance.retirementSalary.toFixed(2)}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
        </Grid>
      </Grid>
    </>
  );
}

export default Calculator;
